<template>
  <div class="w-100 mb-4">
    <Header :tabs="false" />    
    <Form @saved="loadData" />
  </div>
</template>

<script>
import Header from "../components/Header";
import Form from "../components/Form";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Header,
    Form,
  },
  data: () => ({}),
  computed: {
    ...mapState("strategic_plans", ["strategic_plan"]),
  },
  methods: {
    ...mapActions("strategic_plans", [
      "ActionShow",
      "ActionLoadPlanStructure",
      "ActionGetObjectives",
    ]),
    async loadData(id) {
      await this.ActionShow({ id: id });
      await this.ActionLoadPlanStructure();
      await this.ActionGetObjectives(id);
    },
  },
  async created() {
    const id = this.$route.params.id;
    this.loadData(id);
  },
};
</script>
